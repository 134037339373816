* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}
header {
  display: flex;
  height: 70px;
  justify-content: center;
  align-items: center;
  box-shadow: 1px 1px #ffffff44;
  overflow: hidden;
  background: #fff;
}
header img {
  width: auto;
  height: 100%;
}
.navbar {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: fit-content;
  z-index: 1;
  gap: 25px;
}
.navbar button {
  color: #8c8c8c;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  min-width: fit-content;
  padding: 5px;
  height: 40px;
  opacity: 10;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  cursor: pointer;
  background: transparent;
  border: none;
  font-family: Mainfont;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.navbar .LoginB {
  width: 150px;
  height: 35px;
  border-radius: 10px;
  background: #03fca9;
  color: #fff;
}
header .navbtn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-size: 1.8rem;
}
header .innav {
  visibility: hidden;
  overflow: hidden;
}
@media only screen and (max-width: 1350px) {
  header {
    padding-left: 50px;
    justify-content: space-around;
  }
}
@media only screen and (max-width: 1150px) {
  header {
    padding-left: 50px;
    justify-content: space-around;
  }
}
@media only screen and (max-width: 1350px) {
  header {
    padding-left: 0px;
    justify-content: space-around;
  }
  header .navbtn {
    visibility: visible;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  header .innav {
    visibility: visible;
    flex-direction: column;
  }
  .navbar img {
    height: 100px;
  }
  .navbar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    outline: solid 1px #ffffff44;
  }
  .navbar button {
    margin: 1rem;
  }
  header nav {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
    background: #fff;
    transition: 1s;
    transform: translateX(100%);
    overflow-y: scroll;
  }
  header .responsive_nav {
    transform: none;
  }
  nav .innav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  nav .innav img {
    margin-bottom: 50px;
  }

  nav a {
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 460px) {
  header {
    padding-left: 0;
    justify-content: space-around;
    gap: 100px;
  }
  header img {
    height: 80%;
  }
}
@media only screen and (max-width: 414px) {
  header {
    padding-left: 0;
    justify-content: space-around;
  }
}
@media only screen and (max-width: 360px) {
  header {
    padding-left: 0;
    justify-content: space-around;
  }
}
