.GymInfoMain {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.GymInfoMain h1 {
  font-size: 24px;
  color: #03fca9;
  font-family: Mainfont;
  margin-bottom: 20px;
}

.GymInfoForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.GymInfoForm label {
  font-size: 18px;
  color: #333;
  font-family: Mainfont;
}

.GymInfoForm input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 2px solid #03fca9;
  border-radius: 10px;
  font-size: 16px;
  font-family: Mainfont;
  box-sizing: border-box;
}

.GymInfoForm button {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: #03fca9;
  color: #fff;
  font-family: Mainfont;
  cursor: pointer;
  font-size: 18px;
  align-self: flex-end;
}

.GymInfoForm button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.error-message {
  color: red;
  font-size: 16px;
  font-family: Mainfont;
  margin-top: 5px;
}
