.SignupMain {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 20px 0;
}

.SignupMain img {
  width: 150px;
  height: auto;
}

.SignupMain h1 {
  font-size: 50px;
  color: #03fca9;
  font-family: Mainfont;
}

.SignupMain p {
  font-size: 20px;
  color: #7b03fc;
  font-family: Mainfont;
}

.SignupInputs {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.SignupInputs input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 2px solid #03fca9;
  border-radius: 10px;
  font-size: 18px;
  font-family: Mainfont;
  box-sizing: border-box;
}

.SignupInputs button {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: #03fca9;
  color: #fff;
  font-family: Mainfont;
  cursor: pointer;
  font-size: 25px;
}

.SignupInputs button:hover {
  background: #7b03fc;
}

@media only screen and (max-width: 1070px) {
  .SignupMain {
    height: fit-content;
    padding: 50px 0;
  }

  .SignupInputs {
    width: 90%;
  }
}

@media only screen and (max-width: 530px) {
  .SignupMain h1 {
    font-size: 35px;
  }

  .SignupMain p {
    font-size: 18px;
  }

  .SignupInputs input {
    font-size: 16px;
  }

  .SignupInputs button {
    font-size: 20px;
  }
}
