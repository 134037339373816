.LoginMain {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background: #fff;
}

.LoginMain img {
  width: 150px;
  height: auto;
}

.LoginMain h1 {
  font-size: 50px;
  color: #03fca9;
  font-family: Mainfont;
}

.LoginMain p {
  font-size: 20px;
  color: #7b03fc;
  font-family: Mainfont;
}

.LoginInputs {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.LoginInputs input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 2px solid #03fca9;
  border-radius: 10px;
  font-size: 18px;
  font-family: Mainfont;
  box-sizing: border-box;
}

.LoginInputs button {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: #03fca9;
  color: #fff;
  font-family: Mainfont;
  cursor: pointer;
  font-size: 25px;
}

.LoginInputs button:hover {
  background: #7b03fc;
}

@media only screen and (max-width: 1070px) {
  .LoginMain {
    height: fit-content;
    padding: 50px 0;
  }

  .LoginInputs {
    width: 90%;
  }
}

@media only screen and (max-width: 530px) {
  .LoginMain h1 {
    font-size: 35px;
  }

  .LoginMain p {
    font-size: 18px;
  }

  .LoginInputs input {
    font-size: 16px;
  }

  .LoginInputs button {
    font-size: 20px;
  }
}
