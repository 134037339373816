.Sec-2 {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  gap: 30px;
  flex-direction: column;
  padding: 20px 0 20px 0;
}

.Sec-2 h1 {
  color: #03fca9;
  font-size: 45px;
  font-family: Mainfont;
}

.Sec-2 div {
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.Sec-2 div p {
  box-shadow: 0 10px 29px 0 #32ffc155;
  background: #fff;
  min-width: 150px;
  min-height: 50px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #8c8c8c;
  padding: 10px;
  font-family: Mainfont;
}

.Sec-2 button {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: #03fca9;
  color: #fff;
  font-family: Mainfont;
  cursor: pointer;
  font-size: 25px;
}
