.Loading-Main {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.Loading-Main img {
  width: 250px;
  height: auto;
  animation: zoomBounce 2s infinite;
}

@keyframes zoomBounce {
  0%,
  100% {
    transform: translateY(-100px) scale(1);
  }
  50% {
    transform: translateY(0) scale(1.2);
  }
}
