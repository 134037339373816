.UserData {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.UserData h1 {
  font-size: 24px;
  color: #03fca9;
  font-family: Mainfont;
  margin-bottom: 20px;
}

.UserData p {
  font-size: 18px;
  color: #333;
  font-family: Mainfont;
  margin: 10px 0;
}

.UserData p strong {
  color: #7b03fc;
}
