.Sec-1 {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.Sec-1 div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.Block1 h1 {
  font-size: 70px;
  color: #03fca9;
  font-family: Mainfont;
  width: 500px;
}

.Block1 .Sec-1-buttons {
  width: 500px;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.Sec-1-buttons button {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: #03fca9;
  color: #fff;
  font-family: Mainfont;
  cursor: pointer;
  font-size: 25px;
}

.Sec-1-buttons :nth-child(2) {
  background: #7b03fc;
}

.Block1 .Sec-1-buttons2 {
  width: 500px;
  height: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  margin-top: 20px;
}

.Sec-1-buttons2 button {
  width: 200px;
  height: 40px;
  border-radius: 10px;
  border: none;
  background: transparent;
  color: #8c8c8c;
  font-family: Mainfont;
  font-size: 20px;
  display: flex;
  justify-content: flex-start;
  align-self: center;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 10px;
  cursor: pointer;
}

.Block2 img {
  width: 500px;
  height: auto;
}

@media only screen and (max-width: 1070px) {
  .Sec-1 {
    height: fit-content;
    flex-direction: column;
    padding: 50px 0 50px 0;
  }

  .Sec-1 div {
    width: 100%;
    padding: 20px;
  }
}

@media only screen and (max-width: 530px) {
  .Block1 h1 {
    width: 100%;
    font-size: 45px;
  }
  .Block1 .Sec-1-buttons {
    width: 100%;
    justify-content: center;
  }
  .Block1 .Sec-1-buttons2 {
    width: 100%;
    justify-content: center;
    margin-top: 0;
  }
  .Sec-1-buttons2 button {
    justify-content: center;
    align-self: center;
  }
  .Block2 img {
    width: 80%;
    height: auto;
  }
}
